<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAdd" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Add City</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="City Name"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  rounded
                  :counter="100"
                  v-model="name"
                  :error-messages="errMsg"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="City Code"
                  required
                  outlined
                  hide-details="auto"
                  dense
                  rounded
                  v-model="code"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="province">
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  item-text="nama_provinsi"
                  item-value="id"
                  v-model="idProvince"
                  :error-messages="provinceErr"
                  :items="province.data"
                  label="province"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="createArea">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "addCity",
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      maxLength: maxLength(100)
    },
    code: {
      required
    },
    idProvince: {
      required
    }
  },
  props: ["dialogAdd"],

  computed: {
    ...mapState({
      province: state => state.area.province
    }),

    errMsg() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Nama Kota maksimal 100 karakter!");
      !this.$v.name.required && errors.push("Nama Kota harus di isi!");
      return errors;
    },
    codeErr() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      !this.$v.code.required && errors.push("Kode Kota harus di isi!");
      return errors;
    },
    provinceErr() {
      const errors = [];
      if (!this.$v.idProvince.$dirty) return errors;
      !this.$v.idProvince.required && errors.push("Provinsi harus di pilih!");
      return errors;
    }
  },
  data() {
    return {
      name: "",
      code: "",
      idProvince: "",
      page: 1,
      perPage: 1000
    };
  },
  mounted() {
    this.getProvince();
  },
  methods: {
    getProvince() {
      let data = {
        page: 1,
        limit: 1000,
        find: ""
      };
      this.$store.dispatch("area/listProvince", data);
    },
    createArea() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v.$invalid);
        return;
      } else {
        this.$store
          .dispatch("area/createCity", {
            nama_kota: this.name,
            kode_kota: this.code,
            provinsi_id: this.idProvince
          })
          .then(data => {
            if (data.data.status == "success") {
              this.name = "";
              this.code = "";
              this.idProvince = "";
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: data.data.message,
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            } else {
              console.log(data);
              Swal.fire({
                icon: "error",
                title: data.data.data.errors.nama[0],
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            }
          });
      }
    }
  }
};
</script>

<style></style>
