<template>
  <v-row justify="start">
    <v-dialog v-model="dialogUpdate" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Update City</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text v-if="item">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nama Kota"
                  required
                  outlined
                  dense
                  rounded
                  :counter="100"
                  v-model="item.nama_kota"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="City Code"
                  required
                  outlined
                  hide-details="auto"
                  dense
                  rounded
                  v-model="item.kode_kota"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="province">
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  item-text="nama_provinsi"
                  item-value="id"
                  v-model="item.provinsi_id"
                  :items="province.data"
                  label="province"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateItem(item)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "updateCampus",
  mixins: [validationMixin],
  validations: {
    nameCampus: {
      required,
      maxLength: maxLength(20)
    }
  },
  props: ["dialogUpdate", "item"],
  computed: {
    ...mapState({
      province: state => state.area.province
    })
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("area/listProvince");
  },
  methods: {
    updateItem(data) {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // } else {
      this.$store.dispatch("area/updateCity", data).then(data => {
        if (data.data.status == "success") {
          this.$emit("false");
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data.data.data.errors.nama[0],
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
      });
      // }
    }
  }
};
</script>

<style></style>
