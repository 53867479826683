<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card
        class="radius-card _60-w pb-3"
        :loading="loading"
        min-height="150px"
      >
        <v-toolbar flat dense>City</v-toolbar>
        <v-card-title class="pa-3">
          <v-btn outlined rounded color="blue" @click="dialogAdd = true"
            ><v-icon>mdi-plus</v-icon> Add City</v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            @keyup="fetchItems"
            dense
            single-line
            solo
            rounded
            hide-details
          ></v-text-field>
        </v-card-title>
        <AddCity @false="closeDialog" v-bind:dialogAdd="dialogAdd" />

        <div class="mt-10" v-if="city">
          <v-data-table
            :headers="headers"
            :items="city.data"
            hide-default-footer
            :page.sync="page"
            :items-per-page="perPage"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="cyan"
                  class="mr-2"
                  @click.prevent="updateItem(item)"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  @click.prevent="deleteItem(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <UpdateCity
            @false="closeDialog"
            v-bind:dialogUpdate="dialogUpdate"
            v-bind:item="item"
          />
          <div class="text-center pa-3">
            <v-pagination
              v-model="page"
              circle
              @input="fetchItems"
              :length="city.meta.last_page"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import AddCity from "../../components/Admin/Modal/addCity.vue";
import UpdateCity from "../../components/Admin/Modal/updateCity.vue";

export default {
  name: "CityManagement",

  components: {
    AddCity,
    UpdateCity
  },

  computed: {
    ...mapState({
      city: state => state.area.city
    })
  },

  data() {
    return {
      dialogAdd: false,
      dialogUpdate: false,
      item: null,
      search: "",
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "nama_kota" },
        { text: "CODE", value: "kode_kota" },
        { text: "ACTIONS", value: "actions" }
      ],
      page: 1,
      perPage: 6
    };
  },

  mounted() {
    this.fetchItems(); //get all data when load page
  },

  methods: {
    fetchItems() {
      //get all data  fucntion
      this.loading = true;
      this.$store
        .dispatch("area/listCity", {
          page: this.page,
          per_page: this.perPage,
          find: this.search
        })
        .then(() => {
          this.loading = false;
        });
    },

    updateItem(item) {
      this.item = item;
      this.dialogUpdate = true;
    },

    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store.dispatch("area/deleteCity", { id: item.id }).then(() => {
            this.fetchItems(); //if success refetch data all
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.dialogAdd = false; //set variable dialog to false
      this.dialogUpdate = false; //set variable dialog to false
      this.fetchItems(); //and refetch data
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
